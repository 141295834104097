import React, { useEffect } from 'react';
import { useUserData } from '../utils/hooks/useUserData';

const initialState = {
	user: null,
	company: null,
	authorized: false,
	loadingAuthState: true,
	pricingPlan: null,
	freeTrial: null,
};

export const UserContext = React.createContext<any>(initialState);

export default function UserProvider({ children }: any) {
	const { user, userData, companyData, loadingAuthState, pricingPlan, freeTrial } = useUserData();

	useEffect(() => {
		if ('#verified' === document.location.hash) {
			document.location.href = document.location.origin + document.location.pathname;
		}
	}, [document.location.hash]); // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<UserContext.Provider
			value={{
				pricingPlan,
				loadingAuthState,
				freeTrial,
				user: userData,
				company: companyData,
				authorized: user !== null,
			}}
		>
			{children}
		</UserContext.Provider>
	);
}
