import React from 'react';
import { useCollection } from 'react-firebase-hooks/firestore';
import { db } from '../config/firebase';
import { TermDisplay } from '../config/types';
import { collection } from 'firebase/firestore';

export type StateProps = {
	loading: boolean;
	terms: {
		[taxonomy: string]: {
			[id: string]: TermDisplay;
		};
	};
};

const initialState: StateProps = {
	loading: true,
	terms: {},
};

export const TermsContext = React.createContext<any>(initialState);

export default function TermsProvider({ children }: any) {
	const [snapshot, loading, error] = useCollection(collection(db, 'terms'));
	const [state, setState] = React.useState<StateProps>(initialState);

	if (error) {
		console.error(error);
	}

	React.useEffect(() => {
		setState((prevState) => ({ ...prevState, loading }));
	}, [loading]);

	React.useEffect(() => {
		if (!snapshot) {
			return undefined;
		}

		const newTerms: any = {};

		snapshot?.forEach((doc) => {
			const data: any = doc.data();

			if (undefined === newTerms[data.taxonomy]) {
				newTerms[data.taxonomy] = {};
			}

			const newTerm: TermDisplay = {
				id: doc.id,
				name: data.name,
				slug: data.slug,
				allPostsNumber: data.allPostsNumber,
				productNumber: data.productNumber,
				postNumber: data.postNumber,
				serviceNumber: data.serviceNumber,
				questionNumber: data.questionNumber,
				taxonomy: data.taxonomy,
			};

			newTerms[data.taxonomy][doc.id] = newTerm;
		});

		setState((prevState) => ({ ...prevState, terms: newTerms }));
	}, [snapshot]);

	return <TermsContext.Provider value={state}>{children}</TermsContext.Provider>;
}
