import React, { FC } from 'react';

type SettingsContext = {
	themeMode: 'light' | 'dark' | null;
	setThemeMode: (newTheme: 'light' | 'dark') => void;
};

const maybeInitialTheme = window.localStorage.getItem('_app_theme') ?? '';

const initialState: SettingsContext = {
	themeMode: ['dark', 'light'].includes(maybeInitialTheme)
		? (maybeInitialTheme as 'light' | 'dark')
		: null,
	setThemeMode: () => null,
};

export const SettingsContext = React.createContext(initialState);

interface Props {
	children: React.ReactNode;
}

const SettingsProvider: FC<Props> = ({ children }) => {
	const [mode, setMode] = React.useState(initialState.themeMode);

	const setThemeMode = (newTheme: 'light' | 'dark') => {
		setMode(newTheme);
	};

	return (
		<SettingsContext.Provider value={{ themeMode: mode, setThemeMode }}>
			{children}
		</SettingsContext.Provider>
	);
};

export default SettingsProvider;
