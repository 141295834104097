import React from 'react';

type ActionProps = {
	type: string;
	payload?: any;
};

const types = {
	SET_STATE: 'breadcrumbs/SET_STATE',
	SET_LOADING: 'breadcrumbs/SET_LOADING',
	SET_CURRENT_PAGE_TITLE: 'breadcrumbs/SET_CURRENT_PAGE_TITLE',
	CLEAR_CURRENT_PAGE_TITLE: 'breadcrumbs/CLEAR_CURRENT_PAGE_TITLE',
	SET_CUSTOM_BREADCRUMBS: 'breadcrumbs/SET_CUSTOM_BREADCRUMBS',
	CLEAR_CUSTOM_BREADCRUMBS: 'breadcrumbs/CLEAR_CUSTOM_BREADCRUMBS',
};

export type CustomBreadcrumbsItemType = {
	title: string;
	path: string;
};

type StateProps = {
	currentPageTitle: string;
	customBreadcrumbs: CustomBreadcrumbsItemType[];
	loading: boolean;
};

const initialState: StateProps = {
	currentPageTitle: '',
	customBreadcrumbs: [],
	loading: false,
};

export const BreadcrumbsContext = React.createContext<any>(initialState);

export function breadcrumbsReducer(state: StateProps, action: ActionProps) {
	switch (action.type) {
		case types.SET_STATE:
			return {
				...state,
				...action.payload,
			};

		case types.SET_CURRENT_PAGE_TITLE:
			return {
				...state,
				currentPageTitle: action.payload.title,
			};

		case types.SET_CUSTOM_BREADCRUMBS:
			return {
				...state,
				customBreadcrumbs: action.payload.items,
			};

		case types.SET_LOADING:
			return {
				...state,
				loading: action.payload.loading,
			};

		case types.CLEAR_CUSTOM_BREADCRUMBS:
			return {
				...state,
				customBreadcrumbs: [],
			};

		case types.CLEAR_CURRENT_PAGE_TITLE:
			return {
				...state,
				currentPageTitle: '',
			};

		default:
			throw new Error(`Unknown action type: ${action.type}`);
	}
}
