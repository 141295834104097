import React from 'react';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import { NotificationContext, notificationReducer } from '../reducer/notification';

export default function NotificationProvider({ children }: any) {
	const initialUserState = React.useContext(NotificationContext);
	const [state, dispatch] = React.useReducer(notificationReducer, initialUserState);

	const { visible, type, message } = state;

	const handleClose = () => {
		dispatch({
			type: 'notification/CLEAR',
		});
	};

	return (
		<NotificationContext.Provider value={{ state, dispatch }}>
			<>
				{children}
				<Snackbar
					anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
					open={visible}
					autoHideDuration={3000}
					onClose={handleClose}
				>
					<Alert id="notification-alert" onClose={handleClose} severity={type}>
						{message}
					</Alert>
				</Snackbar>
			</>
		</NotificationContext.Provider>
	);
}
