import React from 'react';
import { BreadcrumbsContext, breadcrumbsReducer } from '../reducer/breadcrumbs';

export default function BreadcrumbsProvider({ children }: any) {
	const initialState = React.useContext(BreadcrumbsContext);
	const [state, dispatch] = React.useReducer(breadcrumbsReducer, initialState);

	return (
		<BreadcrumbsContext.Provider value={{ state, dispatch }}>
			{children}
		</BreadcrumbsContext.Provider>
	);
}
