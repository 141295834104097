import { FirebaseOptions, initializeApp } from 'firebase/app';
import { getAnalytics } from 'firebase/analytics';
import { connectFunctionsEmulator, getFunctions } from 'firebase/functions';
import { connectAuthEmulator, getAuth } from 'firebase/auth';
import { connectFirestoreEmulator, getFirestore } from 'firebase/firestore';
import { connectDatabaseEmulator, getDatabase } from 'firebase/database';
import { connectStorageEmulator, getStorage } from 'firebase/storage';
import { AppEnvironment } from './types';

let appEnv: AppEnvironment = 'production';

if (window.location.hostname === 'localhost') {
	appEnv = 'development';
}

export const APP_ENV: AppEnvironment = appEnv;

export const projectId = APP_ENV === 'production' ? 'aptivos-53e62' : 'demo-aptivos-s8d7f';

const firebaseConfig: FirebaseOptions = {
	apiKey: 'AIzaSyBvupngXksLXd6eGAyrz-dCWn1G4RCKU4s',
	authDomain: `${projectId}.firebaseapp.com`,
	databaseURL: `https://${projectId}.firebaseio.com`,
	projectId,
	storageBucket: `${projectId}.appspot.com`,
	messagingSenderId: '420251317876',
	appId: '1:420251317876:web:fc1d2908dd453e9878c321',
	measurementId: 'G-H2QCPERQ7H',
};

const app = initializeApp(firebaseConfig);
export const analytics = getAnalytics(app);
export const functions = getFunctions(app);
export const auth = getAuth(app);
export const db = getFirestore(app);
export const database = getDatabase(app);
export const storage = getStorage(app);

if (APP_ENV === 'development') {
	connectAuthEmulator(auth, 'http://localhost:9098');
	connectFunctionsEmulator(functions, 'localhost', 5001);
	connectFirestoreEmulator(db, 'localhost', 8080);
	connectDatabaseEmulator(database, 'localhost', 9001);
	connectStorageEmulator(storage, 'localhost', 9199);
}
