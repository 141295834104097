import { APP_ENV } from '../config/firebase';
import { ApolloClient, InMemoryCache } from '@apollo/client';

const client = new ApolloClient({
	uri: `https://aptivos-${APP_ENV}.hasura.app/v1/graphql`,
	headers: {
		'content-type': 'application/json',
		'x-hasura-admin-secret': process.env[
			`REACT_APP_HASURA_${APP_ENV.toUpperCase()}_SECRET`
		] as string,
	},
	cache: new InMemoryCache(),
});

export default client;
