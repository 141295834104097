import React, { type FC, type LazyExoticComponent } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import LoadingScreen from '../components/LoadingScreen';
import { UserContext } from '../provider/user';

export type SingleRoute = {
	path: string;
	component: LazyExoticComponent<() => JSX.Element> | LazyExoticComponent<FC>;
	type: string;
	role?: string;
	title?: string;
};

type Routes = SingleRoute[];

const adminRoutes: Routes = [
	{
		path: '/',
		component: React.lazy(() => import('../pages/home/PublicHomePage')),
		type: 'authorized',
		role: 'admin',
		title: 'Home',
	},
	{
		path: '/profile',
		component: React.lazy(() => import('../pages/profile')),
		type: 'authorized',
		role: 'admin',
		title: 'Profile',
	},
	{
		path: '/admin/users',
		component: React.lazy(() => import('../pages/admin/users/IndexPage')),
		type: 'authorized',
		role: 'admin',
		title: 'Users',
	},
	{
		path: '/admin/providers',
		component: React.lazy(() => import('../pages/admin/providers')),
		type: 'authorized',
		role: 'admin',
		title: 'Solution Providers',
	},
	{
		path: '/admin/providers/:slug',
		component: React.lazy(() => import('../pages/admin/providers/single')),
		type: 'authorized',
		role: 'admin',
		title: 'Solution Providers',
	},
	{
		path: '/admin/providers/:slug/users',
		component: React.lazy(() => import('../pages/admin/providers/single/users')),
		type: 'authorized',
		role: 'admin',
		title: 'Users',
	},
	{
		path: '/admin/providers/:slug/content',
		component: React.lazy(() => import('../pages/admin/providers/single/content')),
		type: 'authorized',
		role: 'admin',
		title: 'Content',
	},
	{
		path: '/admin/providers/:slug/products',
		component: React.lazy(() => import('../pages/admin/providers/single/products')),
		type: 'authorized',
		role: 'admin',
		title: 'Products',
	},
	{
		path: '/admin/providers/:slug/products/add',
		component: React.lazy(() => import('../pages/admin/providers/single/products/add')),
		type: 'authorized',
		role: 'admin',
		title: 'Add product',
	},
	{
		path: '/admin/providers/:slug/products/:postSlug',
		component: React.lazy(() => import('../pages/admin/providers/single/products/single')),
		type: 'authorized',
		role: 'admin',
		title: 'Product',
	},
	{
		path: '/admin/providers/:slug/products/:postSlug/edit',
		component: React.lazy(() => import('../pages/admin/providers/single/products/edit')),
		type: 'authorized',
		role: 'admin',
		title: 'Add product',
	},
	{
		path: '/admin/providers/:slug/products-supported',
		component: React.lazy(() => import('../pages/admin/providers/single/products-supported')),
		type: 'public',
		title: 'Products Supported',
	},
	{
		path: '/admin/providers/:slug/services',
		component: React.lazy(() => import('../pages/admin/providers/single/services')),
		type: 'authorized',
		role: 'admin',
		title: 'Services',
	},
	{
		path: '/admin/providers/:slug/services/add',
		component: React.lazy(() => import('../pages/admin/providers/single/services/add')),
		type: 'authorized',
		role: 'admin',
		title: 'Add service',
	},
	{
		path: '/admin/providers/:slug/services/:postSlug',
		component: React.lazy(() => import('../pages/admin/providers/single/services/single')),
		type: 'authorized',
		role: 'admin',
		title: 'Service',
	},
	{
		path: '/admin/providers/:slug/services/:postSlug/edit',
		component: React.lazy(() => import('../pages/admin/providers/single/services/edit')),
		type: 'authorized',
		role: 'admin',
		title: 'Edit service',
	},
	{
		path: '/admin/providers/:slug/posts/add',
		component: React.lazy(() => import('../pages/admin/providers/single/posts/add')),
		type: 'authorized',
		role: 'admin',
		title: 'Add post',
	},
	{
		path: '/admin/providers/:slug/posts',
		component: React.lazy(() => import('../pages/admin/providers/single/posts')),
		type: 'authorized',
		role: 'admin',
		title: 'Posts',
	},
	{
		path: '/admin/providers/:slug/posts/:postSlug',
		component: React.lazy(() => import('../pages/admin/providers/single/posts/single')),
		type: 'authorized',
		role: 'admin',
		title: 'Post',
	},
	{
		path: '/admin/providers/:slug/posts/:postSlug/edit',
		component: React.lazy(() => import('../pages/admin/providers/single/posts/edit')),
		type: 'authorized',
		role: 'admin',
		title: 'Edit post',
	},
	{
		path: '/admin/providers/:slug/integrators',
		component: React.lazy(() => import('../pages/admin/providers/single/integrators')),
		type: 'authorized',
		role: 'admin',
		title: 'Integrators',
	},
	// {
	// 	path: '/admin/providers/:slug/broadcasting',
	// 	component: React.lazy(() => import('../pages/admin/providers/single/broadcasting')),
	// 		// 	type: 'authorized',
	// 	role: 'admin',
	// 	title: 'Broadcasting',
	// },
	{
		path: '/admin/providers/:slug/manage-company',
		component: React.lazy(() => import('../pages/admin/providers/single/manage-company')),
		type: 'authorized',
		role: 'admin',
		title: 'Manage Company',
	},
	{
		path: '/admin/terms',
		component: React.lazy(() => import('../pages/admin/terms/IndexPage')),
		type: 'authorized',
		role: 'admin',
		title: 'Terms',
	},
	{
		path: '/admin/import',
		component: React.lazy(() => import('../pages/admin/import')),
		type: 'authorized',
		role: 'admin',
		title: 'Import',
	},
	{
		path: '/admin/utils',
		component: React.lazy(() => import('../pages/admin/utils')),
		type: 'authorized',
		role: 'admin',
		title: 'Utils',
	},
	{
		path: '/admin/providers/:slug/events/add',
		component: React.lazy(() => import('../pages/admin/providers/single/events/add')),
		type: 'authorized',
		role: 'admin',
		title: 'Add event',
	},
	{
		path: '/admin/providers/:slug/events',
		component: React.lazy(() => import('../pages/admin/providers/single/events')),
		type: 'authorized',
		role: 'admin',
		title: 'Events',
	},
	{
		path: '/admin/providers/:slug/events/:eventSlug',
		component: React.lazy(() => import('../pages/admin/providers/single/events/single')),
		type: 'authorized',
		role: 'admin',
		title: 'Event',
	},
	{
		path: '/admin/providers/:slug/events/:eventSlug/edit',
		component: React.lazy(() => import('../pages/admin/providers/single/events/edit')),
		type: 'authorized',
		role: 'admin',
		title: 'Edit event',
	},
	{
		path: '/admin/providers/:slug/job-postings/add',
		component: React.lazy(() => import('../pages/admin/providers/single/job-postings/add')),
		type: 'authorized',
		role: 'admin',
		title: 'Add Job Posting',
	},
	{
		path: '/admin/providers/:slug/job-postings',
		component: React.lazy(() => import('../pages/admin/providers/single/job-postings')),
		type: 'authorized',
		role: 'admin',
		title: 'Job Postings',
	},
	{
		path: '/admin/providers/:slug/job-postings/:jobPostingsSlug',
		component: React.lazy(() => import('../pages/admin/providers/single/job-postings/single')),
		type: 'authorized',
		role: 'admin',
		title: 'Job Posting',
	},
	{
		path: '/admin/providers/:slug/job-postings/:jobPostingsSlug/edit',
		component: React.lazy(() => import('../pages/admin/providers/single/job-postings/edit')),
		type: 'authorized',
		role: 'admin',
		title: 'Edit Job Posting',
	},
	{
		path: '/forum/add',
		component: React.lazy(() => import('../pages/forum/AddPage')),
		type: 'authorized',
		role: 'admin',
		title: 'Ask Question',
	},
	{
		path: '/forum/:slug/edit',
		component: React.lazy(() => import('../pages/forum/EditPage')),
		type: 'authorized',
		role: 'admin',
		title: 'Edit Question',
	},
	{
		path: '/invitations',
		component: React.lazy(() => import('../pages/invitations')),
		type: 'authorized',
		role: 'admin',
		title: 'Invitations',
	},
	{
		path: '/admin/export',
		component: React.lazy(() => import('../pages/admin/export')),
		type: 'authorized',
		role: 'admin',
		title: 'Export',
	},
	{
		path: '/metrics',
		component: React.lazy(() => import('../pages/admin/metrics')),
		type: 'authorized',
		role: 'admin',
		title: 'Metrics',
	},
];

const publicRoutes: Routes = [
	{
		path: '/',
		component: React.lazy(() => import('../pages/home/PublicHomePage')),
		type: 'public',
		title: 'Home',
	},
	{
		path: '/posts',
		component: React.lazy(() => import('../pages/posts/IndexPage')),
		type: 'public',
		title: 'Posts',
	},
	{
		path: '/posts/:slug',
		component: React.lazy(() => import('../pages/posts/SinglePage')),
		type: 'public',
		title: 'Posts',
	},
	{
		path: '/events',
		component: React.lazy(() => import('../pages/events/IndexPage')),
		type: 'public',
		title: 'Events',
	},
	{
		path: '/events/:slug',
		component: React.lazy(() => import('../pages/events/SinglePage')),
		type: 'public',
		title: 'Events',
	},
	{
		path: '/job-postings',
		component: React.lazy(() => import('../pages/job-postings/IndexPage')),
		type: 'public',
		title: 'Job Postings',
	},
	{
		path: '/job-postings/:slug',
		component: React.lazy(() => import('../pages/job-postings/SinglePage')),
		type: 'public',
		title: 'Job Postings',
	},
	{
		path: '/networking',
		component: React.lazy(() => import('../pages/networking')),
		type: 'public',
		title: 'Networking',
	},
	{
		path: '/news',
		component: React.lazy(() => import('../pages/news')),
		type: 'public',
		title: 'News Feed',
	},
	{
		path: '/providers',
		component: React.lazy(() => import('../pages/providers')),
		type: 'public',
		title: 'Solution Providers',
	},
	{
		path: '/search',
		component: React.lazy(() => import('../pages/search')),
		type: 'public',
		title: 'Search',
	},
	{
		path: '/providers/:slug',
		component: React.lazy(() => import('../pages/providers/single')),
		type: 'public',
		title: 'Solution Provider',
	},
	{
		path: '/providers/:slug/content',
		component: React.lazy(() => import('../pages/providers/single/content')),
		type: 'public',
		title: 'Content',
	},
	{
		path: '/providers/:slug/products',
		component: React.lazy(() => import('../pages/providers/single/products')),
		type: 'public',
		title: 'Products',
	},
	{
		path: '/providers/:slug/products/:postSlug',
		component: React.lazy(() => import('../pages/providers/single/products/single')),
		type: 'public',
		title: 'Product',
	},
	{
		path: '/providers/:slug/products-supported',
		component: React.lazy(() => import('../pages/providers/single/products-supported')),
		type: 'public',
		title: 'Products Supported',
	},
	{
		path: '/providers/:slug/integrators',
		component: React.lazy(() => import('../pages/providers/single/integrators')),
		type: 'public',
		title: 'System Integrators/Resellers',
	},
	{
		path: '/providers/:slug/services',
		component: React.lazy(() => import('../pages/providers/single/services')),
		type: 'public',
		title: 'Services',
	},
	{
		path: '/providers/:slug/services/:postSlug',
		component: React.lazy(() => import('../pages/providers/single/services/single')),
		type: 'public',
		title: 'Service',
	},
	{
		path: '/providers/:slug/posts',
		component: React.lazy(() => import('../pages/providers/single/posts')),
		type: 'public',
		title: 'Posts',
	},
	{
		path: '/providers/:slug/posts/:postSlug',
		component: React.lazy(() => import('../pages/providers/single/posts/single')),
		type: 'public',
		title: 'Post',
	},
	{
		path: '/providers/:slug/events',
		component: React.lazy(() => import('../pages/providers/single/events')),
		type: 'public',
		title: 'Events',
	},
	{
		path: '/providers/:slug/events/:postSlug',
		component: React.lazy(() => import('../pages/providers/single/events/single')),
		type: 'public',
		title: 'Event',
	},
	{
		path: '/providers/:slug/job-postings',
		component: React.lazy(() => import('../pages/providers/single/job-postings')),
		type: 'public',
		title: 'Job Postings',
	},
	{
		path: '/providers/:slug/job-postings/:postSlug',
		component: React.lazy(() => import('../pages/providers/single/job-postings/single')),
		type: 'public',
		title: 'Job Posting',
	},
	{
		path: '/about-us',
		component: React.lazy(() => import('../pages/about-us')),
		type: 'public',
		title: 'About Us',
	},
	{
		path: '/support',
		component: React.lazy(() => import('../pages/support')),
		type: 'public',
		title: 'Support',
	},
	{
		path: '/privacy-policy',
		component: React.lazy(() => import('../pages/static/PrivacyPolicyPage')),
		type: 'public',
		title: 'Privacy Policy',
	},
	{
		path: '/terms-of-service',
		component: React.lazy(() => import('../pages/static/TermsOfServicePage')),
		type: 'public',
		title: 'Terms of Service',
	},
	{
		path: '/login',
		component: React.lazy(() => import('../pages/login')),
		type: 'public',
		title: 'Sign In',
	},
	{
		path: '/register',
		component: React.lazy(() => import('../pages/register')),
		type: 'public',
		title: 'Sign Up',
	},
	{
		path: '/register-admin',
		component: React.lazy(() => import('../pages/register-admin')),
		type: 'public',
		title: 'Sign Up',
	},
	{
		path: '/reset-password',
		component: React.lazy(() => import('../pages/reset-password')),
		type: 'public',
		title: 'Reset Password',
	},
	{
		path: '/forum',
		component: React.lazy(() => import('../pages/forum/IndexPage')),
		type: 'public',
		title: 'Forum',
	},
	{
		path: '/forum/:slug',
		component: React.lazy(() => import('../pages/forum/SinglePage')),
		type: 'public',
		title: 'Question',
	},
	{
		path: '/products',
		component: React.lazy(() => import('../pages/products/IndexPage')),
		type: 'public',
		title: 'Products',
	},
	{
		path: '/products/:slug',
		component: React.lazy(() => import('../pages/products/SinglePage')),
		type: 'public',
		title: 'Product',
	},
	{
		path: '/services',
		component: React.lazy(() => import('../pages/services/IndexPage')),
		type: 'public',
		title: 'Services',
	},
	{
		path: '/services/:slug',
		component: React.lazy(() => import('../pages/services/SinglePage')),
		type: 'public',
		title: 'Service',
	},
	{
		path: '/case-studies',
		component: React.lazy(() => import('../pages/case-studies/IndexPage')),
		type: 'public',
		title: 'Case Studies',
	},
	{
		path: '/case-studies/:slug',
		component: React.lazy(() => import('../pages/case-studies/SinglePage')),
		type: 'public',
		title: 'Case Study',
	},
	{
		path: '/white-papers',
		component: React.lazy(() => import('../pages/white-papers/IndexPage')),
		type: 'public',
		title: 'White Papers',
	},
	{
		path: '/white-papers/:slug',
		component: React.lazy(() => import('../pages/white-papers/SinglePage')),
		type: 'public',
		title: 'White Paper',
	},
	{
		path: '/video-links',
		component: React.lazy(() => import('../pages/video-links/IndexPage')),
		type: 'public',
		title: 'Video Links',
	},
	{
		path: '/video-links/:slug',
		component: React.lazy(() => import('../pages/video-links/SinglePage')),
		type: 'public',
		title: 'Video Link',
	},
	{
		path: '/user-manuals',
		component: React.lazy(() => import('../pages/user-manuals/IndexPage')),
		type: 'public',
		title: 'User Manuals',
	},
	{
		path: '/user-manuals/:slug',
		component: React.lazy(() => import('../pages/user-manuals/SinglePage')),
		type: 'public',
		title: 'User Manual',
	},
	{
		path: '/admin-guides',
		component: React.lazy(() => import('../pages/admin-guides/IndexPage')),
		type: 'public',
		title: 'Admin Guides',
	},
	{
		path: '/admin-guides/:slug',
		component: React.lazy(() => import('../pages/admin-guides/SinglePage')),
		type: 'public',
		title: 'Admin Guide',
	},
	{
		path: '/release-notes',
		component: React.lazy(() => import('../pages/release-notes/IndexPage')),
		type: 'public',
		title: 'Release Notes',
	},
	{
		path: '/release-notes/:slug',
		component: React.lazy(() => import('../pages/release-notes/SinglePage')),
		type: 'public',
		title: 'Release Note',
	},
	{
		path: '/marketing-materials',
		component: React.lazy(() => import('../pages/marketing-materials/IndexPage')),
		type: 'public',
		title: 'Marketing Materials',
	},
	{
		path: '/marketing-materials/:slug',
		component: React.lazy(() => import('../pages/marketing-materials/SinglePage')),
		type: 'public',
		title: 'Marketing Material',
	},
	{
		path: '/:postTypeRoute/category/:termSlug',
		component: React.lazy(() => import('../pages/archives/category')),
		type: 'public',
		title: 'Category archive',
	},
	{
		path: '/:postTypeRoute/tag/:termSlug',
		component: React.lazy(() => import('../pages/archives/tag')),
		type: 'public',
		title: 'Tag archive',
	},
	{
		path: '/messenger',
		component: React.lazy(() => import('../pages/messenger')),
		type: 'public',
		title: 'Messenger',
	},
	{
		path: '/community',
		component: React.lazy(() => import('../pages/community/SinglePage')),
		type: 'public',
		title: 'Community',
	},
	// {
	// 	path: '/community/:slug',
	// 	component: React.lazy(() => import('../pages/community/SingleRoom')),
	// 		// 	type: 'public',
	// 	title: 'Community',
	// },
	// {
	// 	path: '/providers/:slug/broadcasting',
	// 	component: React.lazy(() => import('../pages/providers/single/broadcasting')),
	// 		// 	type: 'public',
	// 	title: 'Broadcasting',
	// },
	{
		path: '/topics',
		component: React.lazy(() => import('../pages/topics')),
		type: 'public',
		title: 'Topics',
	},
	{
		path: '/interviews',
		component: React.lazy(() => import('../pages/interviews')),
		type: 'public',
		title: 'Interviews',
	},
	{
		path: '/interviews/:slug',
		component: React.lazy(() => import('../pages/interviews/single')),
		type: 'public',
		title: 'Interview',
	},
];

const memberRoutes: Routes = [
	{
		path: '/',
		component: React.lazy(() => import('../pages/home/PublicHomePage')),
		type: 'authorized',
		role: 'member',
		title: 'Home',
	},
	{
		path: '/favorites',
		component: React.lazy(() => import('../pages/favorites')),
		type: 'authorized',
		role: 'member',
		title: 'Favorites',
	},
	{
		path: '/invitations',
		component: React.lazy(() => import('../pages/invitations')),
		type: 'authorized',
		role: 'member',
		title: 'Invitations',
	},
	{
		path: '/profile',
		component: React.lazy(() => import('../pages/profile')),
		type: 'authorized',
		role: 'member',
		title: 'Profile',
	},
	{
		path: '/company',
		component: React.lazy(() => import('../pages/company')),
		type: 'authorized',
		role: 'member',
		title: 'Law firm',
	},
	{
		path: '/company/users',
		component: React.lazy(() => import('../pages/company/users')),
		type: 'authorized',
		role: 'member',
		title: 'Users',
	},
	{
		path: '/forum/add',
		component: React.lazy(() => import('../pages/forum/AddPage')),
		type: 'authorized',
		role: 'member',
		title: 'Ask Question',
	},
	{
		path: '/forum/:slug/edit',
		component: React.lazy(() => import('../pages/forum/EditPage')),
		type: 'authorized',
		role: 'member',
		title: 'Edit Question',
	},
];

const vendorRoutes: Routes = [
	{
		path: '/',
		component: React.lazy(() => import('../pages/home/PublicHomePage')),
		type: 'authorized',
		role: 'vendor',
		title: 'Home',
	},
	{
		path: '/profile',
		component: React.lazy(() => import('../pages/profile')),
		type: 'authorized',
		role: 'vendor',
		title: 'Profile',
	},
	{
		path: '/pricing',
		component: React.lazy(() => import('../pages/pricing')),
		type: 'authorized',
		role: 'vendor',
		title: 'Pricing Plans',
	},
	{
		path: '/pricing/premium',
		component: React.lazy(() => import('../pages/pricing/premium')),
		type: 'authorized',
		role: 'vendor',
		title: 'Become a Premium Partner',
	},
	{
		path: '/favorites',
		component: React.lazy(() => import('../pages/favorites')),
		type: 'authorized',
		role: 'vendor',
		title: 'Favorites',
	},
	{
		path: '/invitations',
		component: React.lazy(() => import('../pages/invitations')),
		type: 'authorized',
		role: 'vendor',
		title: 'Invitations',
	},
	{
		path: '/my-space',
		component: React.lazy(() => import('../pages/my-space')),
		type: 'authorized',
		role: 'vendor',
		title: 'My space',
	},
	{
		path: '/my-space/content',
		component: React.lazy(() => import('../pages/my-space/content')),
		type: 'authorized',
		role: 'vendor',
		title: 'Content',
	},
	{
		path: '/my-space/users',
		component: React.lazy(() => import('../pages/my-space/users')),
		type: 'authorized',
		role: 'vendor',
		title: 'Content',
	},
	{
		path: '/my-space/billing',
		component: React.lazy(() => import('../pages/my-space/billing')),
		type: 'authorized',
		role: 'vendor',
		title: 'Billing',
	},
	{
		path: '/my-space/integrators',
		component: React.lazy(() => import('../pages/my-space/integrators')),
		type: 'authorized',
		role: 'vendor',
		title: 'System Integrators/Resellers',
	},
	{
		path: '/my-space/products',
		component: React.lazy(() => import('../pages/my-space/products/IndexPage')),
		type: 'authorized',
		role: 'vendor',
		title: 'Products',
	},
	{
		path: '/my-space/products-supported',
		component: React.lazy(() => import('../pages/my-space/products-supported')),
		type: 'authorized',
		role: 'vendor',
		title: 'Products Supported',
	},
	{
		path: '/my-space/add-product',
		component: React.lazy(() => import('../pages/my-space/products/AddPage')),
		type: 'authorized',
		role: 'vendor',
		title: 'Add new product',
	},
	{
		path: '/my-space/products/:slug',
		component: React.lazy(() => import('../pages/my-space/products/SinglePage')),
		type: 'authorized',
		role: 'vendor',
		title: 'My Products',
	},
	{
		path: '/my-space/products/:slug/edit',
		component: React.lazy(() => import('../pages/my-space/products/EditPage')),
		type: 'authorized',
		role: 'vendor',
		title: 'Edit',
	},
	{
		path: '/my-space/services',
		component: React.lazy(() => import('../pages/my-space/services/IndexPage')),
		type: 'authorized',
		role: 'vendor',
		title: 'Services',
	},
	{
		path: '/my-space/add-service',
		component: React.lazy(() => import('../pages/my-space/services/AddPage')),
		type: 'authorized',
		role: 'vendor',
		title: 'Add new service',
	},
	{
		path: '/my-space/services/:slug',
		component: React.lazy(() => import('../pages/my-space/services/SinglePage')),
		type: 'authorized',
		role: 'vendor',
		title: 'My services',
	},
	{
		path: '/my-space/services/:slug/edit',
		component: React.lazy(() => import('../pages/my-space/services/EditPage')),
		type: 'authorized',
		role: 'vendor',
		title: 'Edit',
	},
	{
		path: '/my-space/posts',
		component: React.lazy(() => import('../pages/my-space/posts/IndexPage')),
		type: 'authorized',
		role: 'vendor',
		title: 'Posts',
	},
	{
		path: '/my-space/add-post',
		component: React.lazy(() => import('../pages/my-space/posts/AddPage')),
		type: 'authorized',
		role: 'vendor',
		title: 'Add new post',
	},
	{
		path: '/my-space/posts/:slug',
		component: React.lazy(() => import('../pages/my-space/posts/SinglePage')),
		type: 'authorized',
		role: 'vendor',
		title: 'My Posts',
	},
	{
		path: '/my-space/posts/:slug/edit',
		component: React.lazy(() => import('../pages/my-space/posts/EditPage')),
		type: 'authorized',
		role: 'vendor',
		title: 'Edit',
	},
	{
		path: '/my-space/events',
		component: React.lazy(() => import('../pages/my-space/events/IndexPage')),
		type: 'authorized',
		role: 'vendor',
		title: 'Events',
	},
	{
		path: '/my-space/add-event',
		component: React.lazy(() => import('../pages/my-space/events/AddPage')),
		type: 'authorized',
		role: 'vendor',
		title: 'Add new event',
	},
	{
		path: '/my-space/events/:slug',
		component: React.lazy(() => import('../pages/my-space/events/SinglePage')),
		type: 'authorized',
		role: 'vendor',
		title: 'My events',
	},
	{
		path: '/my-space/events/:slug/edit',
		component: React.lazy(() => import('../pages/my-space/events/EditPage')),
		type: 'authorized',
		role: 'vendor',
		title: 'Edit',
	},
	{
		path: '/my-space/job-postings',
		component: React.lazy(() => import('../pages/my-space/job-postings/IndexPage')),
		type: 'authorized',
		role: 'vendor',
		title: 'Events',
	},
	{
		path: '/my-space/add-job-posting',
		component: React.lazy(() => import('../pages/my-space/job-postings/AddPage')),
		type: 'authorized',
		role: 'vendor',
		title: 'Add new job posting',
	},
	{
		path: '/my-space/job-postings/:slug',
		component: React.lazy(() => import('../pages/my-space/job-postings/SinglePage')),
		type: 'authorized',
		role: 'vendor',
		title: 'My job postings',
	},
	{
		path: '/my-space/job-postings/:slug/edit',
		component: React.lazy(() => import('../pages/my-space/job-postings/EditPage')),
		type: 'authorized',
		role: 'vendor',
		title: 'Edit',
	},
	{
		path: '/providers/:slug/users',
		component: React.lazy(() => import('../pages/providers/single/users')),
		type: 'authorized',
		role: 'vendor',
		title: 'Users',
	},
	{
		path: '/providers/:slug/billing',
		component: React.lazy(() => import('../pages/providers/single/billing')),
		type: 'authorized',
		role: 'vendor',
		title: 'Billing',
	},
	{
		path: '/forum/add',
		component: React.lazy(() => import('../pages/forum/AddPage')),
		type: 'authorized',
		role: 'vendor',
		title: 'Ask Question',
	},
	{
		path: '/forum/:slug/edit',
		component: React.lazy(() => import('../pages/forum/EditPage')),
		type: 'authorized',
		role: 'vendor',
		title: 'Edit Question',
	},
	{
		path: '/metrics',
		component: React.lazy(() => import('../pages/metrics')),
		type: 'authorized',
		role: 'vendor',
		title: 'Metrics',
	},
	{
		path: '/metrics/trends',
		component: React.lazy(() => import('../pages/metrics/Trends')),
		type: 'authorized',
		role: 'vendor',
		title: 'Trends',
	},
	// {
	// 	path: '/my-space/broadcasting',
	// 	component: React.lazy(() => import('../pages/my-space/broadcasting')),
	// 		// 	type: 'authorized',
	// 	role: 'vendor',
	// 	title: 'Broadcasting',
	// },
];

export const routes: Routes = [...adminRoutes, ...publicRoutes, ...memberRoutes, ...vendorRoutes];

const Logout = React.lazy(() => import('../pages/logout'));

const NotVerified = React.lazy(() => import('../pages/NotVerified'));

const CreateVendorCompanyPage = React.lazy(
	() => import('../pages/create-company/CreateVendorCompanyPage'),
);

const NotFound = React.lazy(() => import('../pages/404'));

export default function Router() {
	const { user, authorized, loadingAuthState } = React.useContext(UserContext);

	if (loadingAuthState) {
		return <LoadingScreen />;
	}

	return (
		<BrowserRouter>
			<React.Suspense fallback={<LoadingScreen />}>
				<Routes>
					<Route path="/logout" element={<Logout />} />
					{authorized && user && !user.emailVerified && (
						<Route path="/" element={<NotVerified />} />
					)}
					{authorized && user && !user?.companyId && user?.role === 'vendor' && (
						<Route path="/" element={<CreateVendorCompanyPage />} />
					)}
					{authorized &&
						user?.role === 'member' &&
						routes
							.filter(({ role }) => role === 'member')
							.map((route) => {
								const RouteElement = route.component;

								return (
									<Route
										path={route.path}
										element={<RouteElement />}
										key={route.path}
									/>
								);
							})}
					{authorized &&
						user?.role === 'vendor' &&
						routes
							.filter(({ role }) => role === 'vendor')
							.map((route) => {
								const RouteElement = route.component;

								return (
									<Route
										path={route.path}
										element={<RouteElement />}
										key={route.path}
									/>
								);
							})}
					{authorized &&
						user?.role === 'admin' &&
						routes
							.filter(({ role }) => role === 'admin')
							.map((route) => {
								const RouteElement = route.component;

								return (
									<Route
										path={route.path}
										element={<RouteElement />}
										key={route.path}
									/>
								);
							})}
					{routes
						.filter(({ type }) => type === 'public')
						.map((route) => {
							const RouteElement = route.component;

							return (
								<Route
									path={route.path}
									element={<RouteElement />}
									key={route.path}
								/>
							);
						})}
					<Route path="*" element={<NotFound />} />
				</Routes>
			</React.Suspense>
		</BrowserRouter>
	);
}
