import { DocumentData, FirestoreDataConverter } from 'firebase/firestore';
import { unset } from 'lodash';
import {
	AdminDB1,
	ClaimRequestDisplay,
	EventModel,
	IntegrationModel,
	InvitationModel,
	JobPostingModel,
	MaterialModel,
	MemberDB1,
	MemberModel,
	PostModel,
	ProductModel,
	QuestionModel,
	ServiceModel,
	TInterview,
	TTopic,
	UserDB1,
	UserNotificationModel,
	VendorCompanyModel,
	VendorDB1,
	VendorModel,
} from '../config/types';

export const userConverter: FirestoreDataConverter<AdminDB1 | MemberDB1 | VendorDB1> = {
	fromFirestore(snapshot, options): AdminDB1 | MemberDB1 | VendorDB1 {
		const data = snapshot.data(options);

		const role: UserDB1['role'] = data.role;

		if (role === 'admin') {
			return data as AdminDB1;
		}

		if (role === 'member') {
			return data as MemberDB1;
		}

		if (role === 'vendor') {
			return data as VendorDB1;
		}

		throw new Error(`Unknown user role: ${role}`);
	},
	toFirestore(modelObject): DocumentData {
		return modelObject as DocumentData;
	},
};

type TypeWithoutId = { [key: string]: any };
type TypeWithId = TypeWithoutId & { id: string };

//	To get converter for a type without id property, you should pass any object without id property, assigned to that type
//	(`const converter = universalConverter({} as T)`).
//	To get converter for a type with a string type id property, you should pass an object with non-falsy
//	string type id property, assigned to that particular type
//	(`const converter = universalConverter({id: 'Non-empty string'} as T)`).
//	In both cases you'll get an object of type `FirestoreDataConverter<T>`
export const universalConverter = <T extends TypeWithId | TypeWithoutId>(arg: T) => {
	if (arg.id && typeof arg.id === 'string') {
		const dataConverterWithID: FirestoreDataConverter<T> = {
			fromFirestore(snapshot): T {
				return { ...snapshot.data(), id: snapshot.id } as T;
			},
			toFirestore(modelObject): DocumentData {
				const data = modelObject;
				unset(data, 'id');
				return data as DocumentData;
			},
		};

		return dataConverterWithID;
	} else {
		const dataConverterWithoutID: FirestoreDataConverter<T> = {
			fromFirestore(snapshot): T {
				return snapshot.data() as T;
			},
			toFirestore(modelObject): DocumentData {
				return modelObject as DocumentData;
			},
		};

		return dataConverterWithoutID;
	}
};

export const vendorCompanyConverter = universalConverter({ id: 'id' } as VendorCompanyModel);

export const materialConverter = universalConverter({ id: 'id' } as MaterialModel);

export const integrationConverter = universalConverter({ id: 'id' } as IntegrationModel);

export const postConverter = universalConverter({ id: 'id' } as PostModel);

export const productConverter = universalConverter({ id: 'id' } as ProductModel);

export const eventConverter = universalConverter({ id: 'id' } as EventModel);

export const jobPostingConverter = universalConverter({ id: 'id' } as JobPostingModel);

export const serviceConverter = universalConverter({ id: 'id' } as ServiceModel);

export const memberOrVendorConverter = universalConverter({ id: 'id' } as
	| MemberModel
	| VendorModel);

export const questionConverter = universalConverter({ id: 'id' } as QuestionModel);

export const userNotificationConverter = universalConverter({ id: 'id' } as UserNotificationModel);

export const invitationConverter = universalConverter({ id: 'id' } as InvitationModel);

export const interviewConverter = universalConverter({ id: 'id' } as TInterview);

export const topicConverter = universalConverter({ id: 'id' } as TTopic);

export const claimRequestDisplayConverter = universalConverter({ id: 'id' } as ClaimRequestDisplay);
